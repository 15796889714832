<script>
import Datatable from '@/router/layouts/table-datatable.vue';
// import Editor from '@tinymce/tinymce-vue';
import axios from 'axios';
import Swal from 'sweetalert2';

export default {
  props: ['dataSoal', 'idStatusPeserta'],
  emits: ['afterSave'],
  components: {
    // Editor,
    Datatable,
  },
  watch: {
    dataSoal: function (newVal, oldVal) {
      console.log('dataSoal', newVal, oldVal);
      this.soal = newVal?.soal ?? '';
      this.jawaban = newVal?.jawaban ?? '';
      this.keywords = newVal?.keywords ?? [];
      this.checkGPT(this.jawaban);
    },
    idStatusPeserta: function (newVal, oldVal) {
      console.log('idstatuspeserta', newVal, oldVal);
      this.idstatus = newVal;
    },
  },
  data() {
    return {
      columns: [
        { width: '50px', label: 'No', name: '-' },
        { width: 'auto', label: 'Keywords', name: '-' },
        { width: '75px', label: 'Bobot', name: '-' },
        { width: '150px', label: 'Skor', name: '-' },
      ],
      keywords: this.dataSoal.keywords ?? [],
      soal: this.dataSoal.soal ?? '',
      idsoal: this.dataSoal.idsoal ?? null,
      jawaban: this.dataSoal.jawaban,
      idstatus: this.idStatusPeserta,
      editorConfig: {
        menubar: false, // Show the menu bar
        height: 280,
        resize: false,
        toolbar:
          'undo redo | styles | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image | print preview media | forecolor backcolor emoticons',
        branding: false, // Hide the TinyMCE branding
      },
      gptCheck: '',
    };
  },
  mounted() {
    this.checkGPT(this.jawaban);
  },
  methods: {
    async save() {
      Swal.fire({
        icon: 'warning',
        title: 'Mohon tunggu',
        allowOutsideClick: false,
        showConfirmButton: false,
      });

      const soalSkor = await this.prepareKeyword();
      console.log('this.dataSoal', this.dataSoal)
      const form = {
        status: 1,
        idstatus_peserta: this.idstatus,
        idsoal: this.idsoal,
        soalskor: soalSkor,
      };
      const data = await axios({
        method: 'post',
        url: process.env.VUE_APP_BACKEND_URL_VERSION + 'penilaian/simpan-skor',
        headers: {
          Authorization: 'Bearer ' + localStorage.access_token_penilai,
        },
        data: form,
      })
        .then((response) => response.data)
        .catch((err) => {
          console.log(err);
          return false;
        });

      if (data) {
        console.log(data);
        this.$emit('afterSave');
      }
      Swal.close();
    },
    async prepareKeyword() {
      console.log(this.keywords);
      let soalSkor = [];
      await this.keywords.forEach((e) => {
        soalSkor.push({
          keyword_idkeyword: e.idkeyword,
          skor: e.keyword_skor,
          idskor_keyword: e.idskor_keyword,
          bobot: e.skor,
        });
      });
      return soalSkor;
    },
    async checkGPT(jawaban) {
      if (jawaban) {
        await axios({
          method: 'post',
          url: 'https://api.gptzero.me/v2/predict/text',
          data: {
            author: 'Muhammad Aris Firmansyah',
            checkPlagiarism: false,
            document: jawaban?.jawaban ?? '',
            interpretability_required: false,
            source: 'dashboard',
            tittle: 'Document — 2/16/2024',
            writing_stats_required: true,
          },
        })
          .then((response) => {
            let res = response.data.documents;
            this.gptCheck = res[0].confidence_category;
          })
          .catch((err) => {
            console.log(err);
            this.gptCheck = '';
          });
      }
    },
  },
};
</script>

<template>
  <div class="col-12">
    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="col-12 d-flex justify-content-between">
            <div>
              <div class="card-title">Jawaban Peserta</div>
            </div>
          </div>
          <div class="col-12">
            <hr class="my-2" />
            <div class="card shadow-sm my-1">
              <div class="card-body pt-2">
                <div class="card-title">Soal</div>
                <div
                  class="card-header overflow-auto"
                  style="max-height: 200px"
                >
                  {{ soal }}
                </div>
              </div>
            </div>
          </div>
          <div class="col-12">
            <!-- <hr class="my-2" /> -->
            <div class="card shadow-sm my-1">
              <div class="card-body">
                <div class="card-title">
                  Jawaban Peserta
                  <span
                    class="float-end badge bg-danger"
                    v-if="gptCheck != '' && gptCheck == 'high'"
                  >
                    {{ gptCheck }}
                  </span>
                  <span
                    class="float-end badge bg-success"
                    v-else-if="gptCheck != '' && gptCheck == 'low'"
                  >
                    {{ gptCheck }}
                  </span>
                  <span
                    class="float-end badge bg-warning"
                    v-else-if="gptCheck != ''"
                  >
                    {{ gptCheck }}
                  </span>
                </div>
                <!-- <Editor
                  api-key="wdlv4g0lb9v38bllo9fgy6yxvuo4wnhauak1zpfjfsub4qbl"
                  :init="editorConfig"
                  v-model="jawaban.jawaban"
                  ref="editor"
                ></Editor> -->
                <textarea
                  rows="10"
                  class="form-control form-control-sm"
                  readonly
                  v-model="jawaban.jawaban"
                >
                </textarea>
              </div>
            </div>
          </div>
          <div class="col-12">
            <!-- <hr class="my-2" /> -->
            <div class="card shadow-sm my-1">
              <div class="card-body">
                <div class="card-title">
                  Penilaian
                  <br>
                <small class="text-danger">(* skor skala 0-100)</small>
                </div>
                <Datatable :columns="columns">
                  <tbody>
                    <tr v-for="(row, index) in keywords" :key="index">
                      <td class="align-middle text-center">{{ index + 1 }}</td>
                      <td class="align-middle">{{ row.keyword }}</td>
                      <td class="align-middle text-center">
                        {{ row.skor }}
                      </td>
                      <td class="align-middle">
                        <input
                          type="number"
                          min="0"
                          max="100"
                          class="form-control form-control-sm"
                          v-model="row.keyword_skor"
                        />
                      </td>
                    </tr>
                  </tbody>
                </Datatable>
                <div class="text-end mt-2">
                  <button class="btn btn-primary btn-sm" @click="save()">
                    <i class="fa fa-save me-1"></i>
                    Simpan
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
