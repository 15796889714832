<script>
import appConfig from "@/app.config";
import PenilaiLayout from "../../../layouts/penilai-layout.vue";
import Datatable from "@/router/layouts/table-datatable.vue";
import Pagination from "@/router/layouts/table-pagination.vue";
import JawabanPeserta from "./jawaban-peserta.vue";
import axios from "axios";
import Swal from "sweetalert2";
import CryptoJS from "crypto-js";

export default {
  page: {
    title: "Dashboard",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    PenilaiLayout,
    Datatable,
    Pagination,
    JawabanPeserta,
  },
  data() {
    let columns = [
      { width: "50px", label: "No", name: "-" },
      { width: "auto", label: "Studi Kasus", name: "-" },
      { width: "5%", label: "Skor", name: "-" },
      { width: "8%", label: "Status", name: "-" },
      { width: "150px", label: "Aksi", name: "-" },
    ];
    return {
      title: "ASESEMEN HC - UNIT PELAKSANA SERTIFIKASI",
      isLoading: false,
      columns: columns,
      tableFilter: {
        idkompetensi: 0,
        level: 0,
        idjadwal_peserta: 0,
        status: 1,
        page: 1,
        per_page: 10,
      },
      tableData: [],
      pagination: {
        lastPage: "",
        currentPage: "",
        total: "",
        lastPageUrl: "",
        nextPageUrl: "",
        prevPageUrl: "",
        from: "",
        to: "",
        links: "",
      },
      showSoal: false,
      selectedSoal: null,
      dataSoal: {},
      dataKompetensi: {},
      dataPeserta: {},
    };
  },
  async mounted() {
    await this.decodeParams();
    this.getKompetensi();
    this.getDataPeserta();
    this.getData();
  },
  methods: {
    async decodeParams() {
      let data_param = this.$route.query?.peserta;
      if (!data_param) {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Peserta tidak ditemukan, silahkan menuju ke halaman dashboard Penilaian",
          showCancelButton: false,
          confirmButtonText: "Kembali ke Dashboard Penilaian",
        }).then((result) => {
          if (result.isConfirmed) {
            this.$router.push({
              name: "penilai-dashboard",
            });
          }
        });
      } else {
        let get_param = await CryptoJS.AES.decrypt(
          data_param,
          "4lfahumaNeEd5ME!"
        )
          .toString(CryptoJS.enc.Utf8)
          .split("_");
        console.log(get_param);
        this.tableFilter.idkompetensi = get_param[0];
        this.tableFilter.level = get_param[1];
        this.tableFilter.idjadwal_peserta = get_param[2];
      }
    },
    async getKompetensi() {
      const data = await axios({
        url:
          process.env.VUE_APP_BACKEND_URL_VERSION +
          "kompetensi/" +
          this.tableFilter.idkompetensi,
        method: "get",
        headers: {
          Authorization: "Bearer " + localStorage.access_token_penilai,
        },
      }).then((response) => response.data.data);
      if (data) {
        this.dataKompetensi = data;
        console.log(this.dataKompetensi, data);
      }
    },
    async getDataPeserta() {
      const data = await axios({
        url:
          process.env.VUE_APP_BACKEND_URL_VERSION +
          "penilaian/jadwal-peserta/" +
          this.tableFilter.idjadwal_peserta,
        method: "get",
        headers: {
          Authorization: "Bearer " + localStorage.access_token_penilai,
        },
      }).then((response) => response.data.data);
      if (data) {
        this.dataPeserta = data;
        console.log("data Peserta", this.dataPeserta);
      }
    },
    async getData() {
      this.isLoading = true;
      const data = await axios
        .get(
          process.env.VUE_APP_BACKEND_URL_VERSION +
            "penilaian/list-soal-penilai",
          {
            params: this.tableFilter,
            headers: {
              Authorization: "Bearer " + localStorage.access_token_penilai,
              Accept: "Application/json",
            },
          }
        )
        .then((response) => response.data)
        .catch((err) => {
          let errResponse = err.response.data;
          if (errResponse.meta.code == 403) {
            Swal.fire({
              icon: "error",
              title: "Terjadi Kesalahan",
              text: errResponse.meta.message,
            });
            this.$router.push({ name: "logout" });
            return false;
          }
          Swal.fire({
            icon: "error",
            title: "Terjadi Kesalahan",
            text: errResponse.data,
          });

          console.log(errResponse);
          return false;
        });

      if (data) {
        console.log(data);
        this.tableData = data.data.data;
        this.pagination.lastPage = data.data.last_page;
        this.pagination.currentPage = data.data.current_page;
        this.pagination.total = data.data.total;
        this.pagination.lastPageUrl = data.data.last_page_url;
        this.pagination.nextPageUrl = data.data.next_page_url;
        this.pagination.prevPageUrl = data.data.prev_page_url;
        this.pagination.from = data.data.from;
        this.pagination.to = data.data.to;
        this.pagination.links = data.data.links;
      }
      this.isLoading = false;
    },
    toPage(url) {
      let link = new URLSearchParams(url);
      this.tableFilter.page = link.get("page");
      // this.tableFilter.per_page = link.get('per_page');
      this.getData();
    },
    async pilihSoal(row) {
      Swal.fire({
        icon: "warning",
        title: "Mohon tunggu",
        allowOutsideClick: false,
        showConfirmButton: false,
      });

      this.selectedSoal = row;
      const data = await axios
        .get(process.env.VUE_APP_BACKEND_URL_VERSION + "penilaian/soal-skor", {
          params: {
            idsoal: row.idsoal,
            idstatus_peserta: row.idstatus_peserta,
            jadwal_peserta_idjadwal_peserta: this.tableFilter.idjadwal_peserta,
          },
          headers: {
            Authorization: "Bearer " + localStorage.access_token_penilai,
          },
        })
        .then((response) => response.data.data)
        .catch((err) => {
          console.log(err);
          return false;
        });
      if (!data.jawaban) {
        Swal.fire({
          icon: "error",
          text: "Soal belum dijawab oleh peserta !",
          timer: 2000,
        });
        return;
      }

      if (data) {
        console.log(data, row.pertanyaan);
        this.dataSoal = {
          soal: row.pertanyaan,
          idsoal: row.idsoal,
          jawaban: data.jawaban,
          keywords: data.keywords,
        };
        this.showSoal = true;
      }
      Swal.close();
    },
    async kembali() {
      this.$router.back();
    },
  },
};
</script>

<template>
  <PenilaiLayout>
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-12 d-flex justify-content-between">
                  <div class="card-title">List Soal</div>
                  <button class="btn btn-danger btn-sm me-1" @click="kembali()">
                    <i class="fa fa-arrow-left"></i> Kembali
                  </button>
                </div>
                <div class="col-12">
                  <hr class="my-2" />
                  <div class="form-group row">
                    <label for="" class="form-label col-md-2">Kompetensi</label>
                    <label for="" class="form-label col-md-10">
                      : {{ dataKompetensi?.nama_kompetensi ?? "-" }}
                    </label>
                  </div>
                  <div class="form-group row">
                    <label for="" class="form-label col-md-2">Level</label>
                    <label for="" class="form-label col-md-10">
                      :
                      {{ dataKompetensi?.level_kompetensi?.nama_level ?? "-" }}
                    </label>
                  </div>
                </div>
                <div class="col-12">
                  <hr class="my-2" />
                  <div class="form-group row">
                    <label for="" class="form-label col-md-2"> Nama </label>
                    <label for="" class="form-label col-md-10">
                      : {{ dataPeserta?.nama ?? "-" }}
                    </label>
                  </div>
                  <div class="form-group row">
                    <label for="" class="form-label col-md-2"> NIP </label>
                    <label for="" class="form-label col-md-10">
                      : {{ dataPeserta?.nip ?? "-" }}
                    </label>
                  </div>
                  <div class="form-group row">
                    <label for="" class="form-label col-md-2"> Jabatan </label>
                    <label for="" class="form-label col-md-10">
                      : {{ dataPeserta?.jabatan_nama ?? "-" }}
                    </label>
                  </div>
                </div>
                <div class="col-12">
                  <hr class="my-2" />
                  <div class="row">
                    <Datatable :columns="columns">
                      <tbody>
                        <tr v-if="isLoading">
                          <td class="text-center" colspan="99">
                            <i class="fas fa-spinner fa-spin"></i> Loading...
                          </td>
                        </tr>
                        <tr v-else-if="tableData == ''">
                          <td class="text-center" colspan="99">
                            Data Tidak Tersedia
                          </td>
                        </tr>
                        <tr
                          v-else
                          v-for="(row, index) in tableData"
                          :key="index"
                        >
                          <td class="text-center">
                            {{ pagination.from + index }}
                          </td>
                          <td>
                            {{ row.soal_text }}
                          </td>
                          <td class="text-center">
                            {{ row.total_skor || "0" }}
                          </td>
                          <td class="text-center">
                            <span
                              v-if="row.status_skor_soal == 1"
                              class="badge rounded-pull bg-success"
                            >
                              Sudah Dinilai
                            </span>
                            <span v-else class="badge rounded-pull bg-warning">
                              Belum Dinilai
                            </span>
                          </td>
                          <td>
                            <div class="d-grid">
                              <button
                                class="btn btn-primary btn-sm"
                                @click="pilihSoal(row)"
                              >
                                <i class="fa fa-check me-1"></i> Pilih
                              </button>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </Datatable>
                  </div>
                  <pagination
                    :pagination="pagination"
                    @to-page="toPage"
                  ></pagination>
                </div>
              </div>
            </div>
          </div>
        </div>
        <JawabanPeserta
          v-if="showSoal"
          :dataSoal="dataSoal"
          :idStatusPeserta="selectedSoal.idstatus_peserta"
          @after-save="(showSoal = false), pilihSoal(selectedSoal), getData()"
        />
      </div>
    </div>
  </PenilaiLayout>
</template>

<style scoped>
.nav-head {
  max-width: 1000px !important;
}

.search-pad {
  padding-left: 5px !important;
}

.input-group {
  display: flow;
}

.search-box {
  display: flow;
  align-items: center;
  background-color: #f8f8fb;
  border-radius: 40px;
}

.search-icon {
  position: static;
  line-height: 30px;
}

.search-input input {
  width: -webkit-fill-available;
  border: none;
  outline: none;
  background-color: unset;
  padding: 5px 0px 5px 15px;
}

.position-relative {
  position: relative;
}

.span-notif {
  position: absolute;
  top: 0;
  left: 8px;
  font-size: 8px;
}

.icon-notif {
  position: relative;
  top: 5px;
}

.nav-pills .nav-link {
  border-radius: 5rem;
}
</style>
